import React from 'react'

export default function hero() {
  return (
        <section class="relative home-wrapper items-center overflow-hidden">
        <div class="container z-2 section-index">
            <div class="grid grid-cols-1 md:mt-44 mt-32 text-center items-center">
            <div class="wow animate__animated animate__fadeIn items-center">
                <h4 class="text-white font-bold lg:leading-normal leading-normal text-4xl lg:text-5xl mb-5">Start your Turkish company.</h4>
                <p class="text-slate-400 text-lg max-w-xl mx-auto">The simplest way for anyone to launch and run a Turkish business. Incorporate your company, access one-click growth tools, stay compliant, and manage everything your business needs — all online, from anywhere.</p>

                <div class="items-center grid-cols-9 mt-6 text-center gradient-btn">
                    <div class="mt-6 m-auto text-center gradient--btn">
                    <a href="https://dashboard.launchturkey.com/users/sign_up" class="gradient---btn w-button rounded-lg hover:bg-white hover:text-black">Start my business</a>
                    </div>
                </div>
            </div>
            <div class="home-dashboard mt-8 m-auto z-3 wow animate__animated animate__fadeIn items-center">
                <img className='items-center' src="https://res.cloudinary.com/avomeraydin/image/upload/v1670571612/ezgif-5-89ba70164d_e322os.gif" alt="" class="mover" />
            </div>
            </div>

            <div class="bg-indigo-600 w-8 h-16 z-2 absolute left-2 lg:bottom-28 md:bottom-36 sm:bottom-40 bottom-16"></div>
            <div class="bg-indigo-600/20 w-8 h-16 z-2 absolute left-12 lg:bottom-32 md:bottom-40 sm:bottom-44 bottom-20"></div>

            <div class="bg-indigo-600/20 w-8 h-16 z-2 absolute right-12 xl:bottom-[420px] lg:bottom-[315px] md:bottom-[285px] sm:bottom-80 bottom-32"></div>
            <div class="bg-indigo-600 w-8 h-16 z-2 absolute right-2 xl:bottom-[440px] lg:bottom-[335px] md:bottom-[305px] sm:bottom-[340px] bottom-36"></div>
        </div>
        <img src="https://assets.website-files.com/609486d2fafa37698a57db5b/628e756e9f4514d25e1825d3_agent-herobg.svg" loading="eager" alt="Gradient Background" class="hero-bg saturation"></img>
        </section>
  )
}
