import React from "react"

export default function price() {
  return (
    <>
      <section class="relative md:py-24 py-16 md:pt-0 pt-0">
        <div class="section-index  container">
          <div
            class="grid grid-cols-1 justify-center wow animate__animated animate__fadeInUp"
            data-wow-delay=".1s"
          >
            <div class="relative z-1">
              <div class="grid grid-cols-1 md:text-left text-center justify-center">
                <div class="relative">
                  <img src="https://res.cloudinary.com/avomeraydin/image/upload/v1665494675/assets/images/Screen_Shot_2022-10-11_at_16.24.24_ar80wm.png"></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="relative md:py-24 py-16">
        <div class="section-index container">
          <div
            class="grid lg:grid-cols-12 grid-cols-1 items-center gap-[30px] wow animate__animated animate__fadeInUp"
            data-wow-delay=".1s"
          >
            <div class="lg:col-span-5">
              <div class="lg:text-left text-center">
                <h3 class="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold text-white">
                  Cheapest way to launch
                </h3>

                <p class="text-slate-400 max-w-xl mx-auto">
                  Explore the traditional way and innovative way to establishing
                  new company in Turkey.{" "}
                </p>

                <div class="mt-6">
                  <a
                    href="/pricing"
                    class="btn bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mr-2 mt-2"
                  >
                    <i class="fa-solid fa-calendar-check mr-2 text-xl"></i>Learn More About Pricing
                  </a>
                </div>
              </div>
            </div>

            <div class="lg:col-span-7 mt-8 lg:mt-0">
              <div class="lg:ml-8">
                <div class="grid md:grid-cols-2 grid-cols-1 md:gap-0 gap-[30px]">
                  <div
                    class="group border-b-[3px] dark:border-gray-700 relative shadow dark:shadow-gray-800 rounded-md md:scale-110 z-3 bg-white dark:bg-slate-900 wow animate__animated animate__fadeInUp"
                    data-wow-delay=".3s"
                  >
                    <div class="p-6 py-8">
                      <h6 class="font-bold uppercase mb-5 text-indigo-600">
                        TurkiyeLaunch
                      </h6>

                      <div class="flex mb-5">
                        <span class="text-xl font-semibold">$</span>
                        <span class="price text-4xl font-semibold mb-0">
                          300
                        </span>
                        <span class="text-xl font-semibold self-end mb-1">
                          /per month
                        </span>
                      </div>

                      <ul class="list-none text-slate-400">
                        <li class="mb-1 flex">
                          <i class="fa-solid fa-check  text-indigo-600 text-xl mr-2"></i>{" "}
                          <p className="text-indigo-600">Setup Fee</p>
                        </li>
                        <li class="mb-1 flex">
                          <i class="fa-solid fa-check  text-indigo-600 text-xl mr-2"></i>{" "}
                          <p className="text-indigo-600">12 Month Legal & Financial Support</p>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div
                    class="group border-b-[3px] dark:border-gray-700 relative shadow dark:shadow-gray-800 rounded-md z-2 bg-gray-50 dark:bg-slate-800 wow animate__animated animate__fadeInUp"
                    data-wow-delay=".5s"
                  >
                    <div class="p-6 py-8 md:pl-10">
                      <h6 class="font-bold uppercase mb-5 text-indigo-600">
                        Traditional Law Firm
                      </h6>

                      <div class="flex mb-5">
                        <span class="text-xl font-semibold">$</span>
                        <span class="price text-4xl font-semibold mb-0">
                          10.000
                        </span>
                        <span class="text-xl font-semibold self-end mb-1">
                          /launch
                        </span>
                      </div>

                      <ul class="list-none text-slate-400">
                        <li class="mb-1 flex">
                          <i class="fa-solid fa-check  text-indigo-600 text-xl mr-2"></i>{" "}
                          <p className="text-indigo-600">Legal Services For Incorporation Procedure 3.000 USD</p>
                          
                        </li>
                        <li class="mb-1 flex">
                          <i class="fa-solid fa-check  text-indigo-600 text-xl mr-2"></i>{" "}
                          <p className="text-indigo-600">Virtual Office 100 USD /Per Month</p>
                          
                        </li>
                        <li class="mb-1 flex">
                          <i class="fa-solid fa-check  text-indigo-600 text-xl mr-2"></i>{" "}
                          <p className="text-indigo-600">Accounting 500 USD / Per Month</p>
                          
                        </li>
                        <li class="mb-1 flex">
                          <i class="fa-solid fa-check  text-indigo-600 text-xl mr-2"></i>{" "}
                          <p className="text-indigo-600">Legal Services 500 USD / Per Month</p>
                          
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="section-index  container md:mt-24 mt-16">
          <div
            class="grid md:grid-cols-12 grid-cols-1 items-center wow animate__animated animate__fadeInUp"
            data-wow-delay=".1s"
          >
            <div class="md:col-span-6">
              <h6 class="text-indigo-600 text-sm font-bold uppercase mb-2">
                Guide
              </h6>
              <h3 class="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold text-white">
                Reads Our Latest <br /> Blogs & Guides
              </h3>
            </div>

            <div class="md:col-span-6">
              <p class="text-slate-400 max-w-xl">
                Start working with LaunchTurkey and explore the best experience
                ever.
              </p>
            </div>
          </div>

          <div class="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 mt-8 gap-[30px]">
            <div
              class="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden wow animate__animated animate__fadeInUp"
              data-wow-delay=".3s"
            >
              <div class="content p-6">
                <a
                  href="https://www.launchturkey.com/blog/what-to-consider-before-launching-a-turkish-company"
                  class="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out text-white"
                >
                  What to Consider Before Launching a Turkish Company
                </a>
                <p class="text-slate-400 mt-3">
                  This guide outlines all key considerations every business
                  owner needs to make before incorporating in the international
                  market—from what structure suits them best to which state is
                  the best to incorporate.
                </p>

                <div class="mt-4">
                  <a
                    href="https://www.launchturkey.com/blog/what-to-consider-before-launching-a-turkish-company"
                    class="btn btn-link font-normal hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out"
                  >
                    Read More <i class="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>

            <div
              class="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden wow animate__animated animate__fadeInUp"
              data-wow-delay=".5s"
            >
              <div class="content p-6">
                <a
                  href="https://www.launchturkey.com/blog/incorporating-a-company-in-turkey"
                  class="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out text-white"
                >
                  Incorporating a Company in Turkey
                </a>
                <p class="text-slate-400 mt-3">
                  Whether you're a rockstar developer or a budding entrepreneur,
                  incorporating your company in Turkey is an essential step.
                  Follow this guide on how to get started and know what it means
                  for your startup's future!
                </p>

                <div class="mt-4">
                  <a
                    href="https://www.launchturkey.com/blog/incorporating-a-company-in-turkey"
                    class="btn btn-link font-normal hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out"
                  >
                    Read More <i class="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>

            <div
              class="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden wow animate__animated animate__fadeInUp"
              data-wow-delay=".7s"
            >
              <div class="content p-6">
                <a
                  href="https://www.launchturkey.com/blog/important-details-for-owners-of-a-turkish-company"
                  class="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out text-white"
                >
                  Important Details for Owners of a Turkish Company
                </a>
                <p class="text-slate-400 mt-3">
                  Now that your company is running smoothly, let's make sure
                  you're doing it the right way. This guide contains all the
                  information that you need on stocks, government regulations,
                  and more.
                </p>

                <div class="mt-4">
                  <a
                    href="https://www.launchturkey.com/blog/important-details-for-owners-of-a-turkish-company"
                    class="btn btn-link font-normal hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out"
                  >
                    Read More <i class="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          src="https://res.cloudinary.com/avomeraydin/image/upload/v1665494458/assets/images/bg_sgh9pl.svg"
          loading="eager"
          alt="Gradient Background"
          class="hero-bg saturation"
        ></img>
      </section>
    </>
  )
}
