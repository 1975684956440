import React from 'react'

export default function products() {
  return (
    <section class="relative md:py-24 py-16 overflow-hidden">
    <div class="container md:mt-24 mt-16">
        <div class="grid grid-cols-1 pb-8 text-center wow animate__animated animate__fadeInUp" data-wow-delay=".1s">
        <h3 class="text-white mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Launch your Turkish business from anywhere, in minutes.</h3>

        <p class="text-slate-400 max-w-xl mx-auto">No paperwork or legal headaches. Our online platform automates the entire company formation process with compliance and 24/7 global customer support.</p>
        </div>

        <div class="grid md:grid-cols-2 grid-cols-1 items-center mt-16 gap-[30px]">
        <div class="relative wow animate__animated animate__fadeInLeft" data-wow-delay=".3s">
            <img src="https://res.cloudinary.com/avomeraydin/image/upload/v1670571703/Screen_Shot_2022-12-09_at_10.41.38_vwqd6a.pngs" class="rounded-lg shadow-md dark:shadow-gray-800" alt="" />
            <div class="overflow-hidden absolute lg:h-[400px] h-[320px] lg:w-[400px] w-[320px] bg-indigo-600/5 bottom-0 left-0 rotate-45 -z-1 rounded-3xl"></div>
        </div>

        <div class="lg:ml-8 wow animate__animated animate__fadeInRight" data-wow-delay=".3s">
            <h4 class="text-white mb-4 text-2xl leading-normal font-medium">Form your legal entity.</h4>
            <p class="text-slate-400">Choose an Joint-Stock Company(A.S.) or Limited Liability Company (LTD. STI.) and the registration for your business. We'll guide you through which is best for your business if you don't know.</p>
            <ul class="list-none text-slate-400 mt-4">
            <li class="mb-1 flex"><i class="fa-solid fa-check text-indigo-600 text-xl mr-2 "></i>Joint-Stock Company (A.S.)</li>
            <li class="mb-1 flex"><i class="fa-solid fa-check text-indigo-600 text-xl mr-2 "></i> Limited Liability Company (LTD. STI.)</li>
            <li class="mb-1 flex"><i class="fa-solid fa-check text-indigo-600 text-xl mr-2 "></i>Umbrella Company</li>
            </ul>
        </div>
        </div>
    </div>

    <div class="container md:mt-24 mt-16">
        <div class="grid md:grid-cols-2 grid-cols-1 items-center gap-[30px]">
        <div class="relative order-1 md:order-2 wow animate__animated animate__fadeInRight" data-wow-delay=".5s">
            <img src="https://res.cloudinary.com/avomeraydin/image/upload/v1665487897/assets/images/Screen_Shot_2022-10-11_at_14.31.26_sstcp4.png" class="rounded-lg shadow-md dark:shadow-gray-800" alt="" />
            <div class="overflow-hidden absolute lg:h-[400px] h-[320px] lg:w-[400px] w-[320px] bg-indigo-600/5 bottom-0 right-0 rotate-45 -z-1 rounded-3xl"></div>
        </div>

        <div class="lg:mr-8 order-2 md:order-1 wow animate__animated animate__fadeInLeft" data-wow-delay=".5s">
            <h4 class="text-white mb-4 text-2xl leading-normal font-medium">Open a bank account</h4>
            <p class="text-slate-400">Don't wait too much. We'll give you a bank account faster.</p>
        </div>
        </div>
    </div>

    <div class="container md:mt-24 mt-16">
        <div class="grid grid-cols-1 pb-8 text-center wow animate__animated animate__fadeInUp" data-wow-delay=".1s">
        <h3 class="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold text-white">Instant access to the best Turkish tools to run your business.</h3>

        <p class="text-slate-400 max-w-xl mx-auto">Apply for banking, payment processing, payroll, accounting, and more, in one click with Launch Turkey Loop.</p>
        </div>

    </div>
    <img src="https://res.cloudinary.com/avomeraydin/image/upload/v1665494458/assets/images/bg_sgh9pl.svg" loading="eager" alt="Gradient Background" class="hero-bg saturation"></img>
    </section>

  )
}

